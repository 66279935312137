<div class="privacy">
  <h1>Privacy Policy</h1>
  <div class="content">
    <label>This Privacy Policy governs the manner in which Destiny Recipes. collects, uses, maintains and discloses information collected from users (each, a "User") of the raid.report website ("Site"). This privacy policy applies to the Site and all products and services offered by Destiny Recipes.</label>
    <div>
      <h2>Analytics Data</h2>
      <label>We may collect non-personal identification information about how users interact with our Site. Such informations may include the type of browser, the means of connection to our Site and other technical informations about the pages and links Users interact with. Those data are shared to our Analytics third party service : Google Analytics.
      </label>
    </div>
    <div>
      <h2>Advertising</h2>
      <label>Ads appearing on our site may be delivered to Users by advertising partners, who may set cookies. These cookies allow the ad server to recognize your computer each time they send you an online advertisement to compile non personal identification information about you or others who use your computer. This information allows ad networks to, among other things, deliver targeted advertisements that they believe will be of most interest to you. This privacy policy does not cover the use of cookies by any advertisers. In addition, NAI member companies serve cookies via this website as part of the opt-out process. To enable a consumer to opt out of IBA by a member company, the member company must set an "opt-out cookie" on the consumer's browser. Please note that if you delete any opt-out cookies obtained using the NAI opt-out page, such as by clearing all cookies in your browser, you will need to return to the opt-out page to renew your choices. However, the DAA offers a tool that helps to preserve your opt-out cookies and prevent them from being deleted. To learn more about how our opt-out process works, click<a href="http://optout.networkadvertising.org/?c=1#!%2F" target="_blank" rel="noopener noreferrer"> here</a>.
      </label>
    </div>
    <div>
      <h2>Third party websites</h2>
      <label>Users may find advertising or other content on our Site that link to the sites and services of our partners, suppliers, advertisers, sponsors, licensors and other third parties. We do not control the content or links that appear on these sites and are not responsible for the practices employed by websites linked to or from our Site. In addition, these sites or services, including their content and links, may be constantly changing. These sites and services may have their own privacy policies and customer service policies. Browsing and interaction on any other website, including websites which have a link to our Site, is subject to that website's own terms and policies.
      </label>
    </div>
    <div>
      <h2>Google Adsense</h2>
      <label>Some of the ads may be served by Google. Google may use cookies to serve ads based on a User’s prior visits to the Site or other websites. Google's use of advertising cookies enables it and its partners to serve ads to a User based on their visit to your sites and/or other sites on the Internet. Users may opt out of personalized advertising by visiting <a href="https://adssettings.google.com" target="_blank">adssettings.google.com</a>. Some of the ads may be served by third-party vendors and ad networks. A User can opt out of a third-party vendor's use of cookies for personalized advertising by visiting <a href="https://www.aboutads.info" target="_blank">www.aboutads.info</a>.
      </label>
    </div>
    <div>
      <h2>Advertising Privacy Settings</h2>
      <label>FOR EU USERS ONLY: When you use our site, pre-selected companies may access and use certain information on your device and about your interests to serve ads or personalized content. You may revisit or change consent-choices at any time by clicking <a href="#cmp">here</a>.
      </label>
    </div>
    <div>
      <h2>Changes to this privacy policy</h2>
      <label>Destiny Recipes has the discretion to update this privacy policy at any time. When we do, we will revise the updated date at the bottom of this page. We encourage Users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications.
      </label>
    </div>
    <div>
      <h2>Your acceptance of these terms</h2>
      <label>By using this Site, you signify your acceptance of this policy. If you do not agree to this policy, please do not use our Site. Your continued use of the Site following the posting of changes to this policy will be deemed your acceptance of those changes.
      </label>
    </div><br>
    <label>This document was last updated on August 24, 2024</label>
  </div>
</div>
