import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { ErrorHandler, NgModule, isDevMode } from '@angular/core';

import { AppComponent } from './app.component';
import { NgxIndexedDBModule } from 'ngx-indexed-db';
import { environment } from '@Env';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faAlignLeft,
  faBraille,
  faExchangeAlt,
  faGlobe,
  faHeart,
  faMicroscope,
  faQuestionCircle,
  faSignOutAlt,
  faSort,
  faTasks,
  faVial
} from '@fortawesome/free-solid-svg-icons';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule } from '@angular/forms';
import { HomeComponent } from './home/home.component';
import { AppRoutingModule } from './app.routing.module';
import { AppCommonModule } from '@Common/app-common.module';
import { NotFoundComponent } from './not-found/not-found.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { TokenInterceptor } from '@Services/token.interceptor';
import { faBuromobelexperte, faTwitter, faWatchmanMonitoring } from '@fortawesome/free-brands-svg-icons';
import { GlobalErrorHandler } from '@Common/global-error-handler';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc-noscope';
import { ClipboardModule } from 'ngx-clipboard';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { ServiceWorkerModule } from '@angular/service-worker';

// We need a factory, since localStorage is not available during AOT build time.
export function storageFactory(): OAuthStorage {
  if (typeof localStorage !== 'undefined') {
    return localStorage;
  }
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', `.json?v=${environment.version}`);
}
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NotFoundComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppCommonModule,
    NgxIndexedDBModule.forRoot(environment.dbConfig),
    FontAwesomeModule,
    NgxPaginationModule,
    ClipboardModule,
    FormsModule,
    NgxGoogleAnalyticsModule.forRoot('G-1RCMV7B5MF'),
    NgxGoogleAnalyticsRouterModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [
          'https://www.bungie.net/platform/user/',
          'https://www.bungie.net/platform/content/',
          'https://www.bungie.net/platform/forum/',
          'https://www.bungie.net/platform/groupv2/',
          'https://www.bungie.net/platform/tokens/',
          'https://www.bungie.net/platform/destiny2/',
          'https://www.bungie.net/platform/communitycontent/',
          'https://www.bungie.net/platform/fireteam/',
          'https://www.bungie.net/platform/trending/',
        ],
        sendAccessToken: true
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: OAuthStorage, useFactory: storageFactory },
    provideClientHydration()
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private library: FaIconLibrary) {
    library.addIcons(
      faSort,
      faGlobe,
      faSignOutAlt,
      faExchangeAlt,
      faQuestionCircle,
      faTwitter,
      faHeart,
      faWatchmanMonitoring,
      faBuromobelexperte,
      faTasks,
      faBraille,
      faAlignLeft,
      faVial,
      faMicroscope,
    );
  }

}
