<div class="content" id="wrapper">
  <app-header></app-header>
  <div class="main-content">
    <router-outlet></router-outlet>
  </div>

  <div class="footer">
    <div class="logos">
      <a href="https://destinyrecipes.com"><img src="assets/logo/logo.png" alt="Destiny Recipes"></a>
      <a href="https://method.gg" target="_blank"><img src="assets/logo/Method_Logo_Icon_Only.png" alt="Method Gaming"></a>
    </div>
    <small class="credit">&copy; Destiny Recipes 2020-2024 - Provided by <a href="https://method.gg" target="_blank">Method</a> - v{{version}} - <a href="javascript:" class="clear" (click)="hardRefresh()">Reset site data</a> - <a (click)="toggleTooltipOrientation()" href="javascript:" class="clear">Toggle tooltip orientation</a> </small>
    <small class="credit clarity"><a routerLink="/privacy" class="clear">Privacy Policy</a> - <a routerLink="/terms" class="clear">Terms and conditions</a></small>
    <small class="credit smer">Destiny 2 is a registered trademark of Bungie. Trademarks are the property of their respective owners. Game materials copyright Bungie. </small>

  </div>
</div>
<cr-equipment-tooltip [side]="tooltipSide" [item]="coreService.tooltipContent" *ngIf="coreService.tooltipOpened && coreService.tooltipType !== 'target'" [position]="coreService.tooltipPosition"
                      [tooltipInstance]="coreService.tooltipContentInstance"></cr-equipment-tooltip>
<cr-target-equipment-tooltip [side]="tooltipSide" [item]="coreService.tooltipContent" *ngIf="coreService.tooltipOpened && coreService.tooltipType === 'target'" [position]="coreService.tooltipPosition"
                             [tooltipInstance]="coreService.tooltipContentInstance"></cr-target-equipment-tooltip>
<app-perk-popup [perkPopupConfig]="coreService.perkPopupConfig" [position]="coreService.perkPopupPosition" *ngIf="coreService.perkPopupConfig"></app-perk-popup>
<app-watcher-status *ngIf="authService.authenticated"></app-watcher-status>
<div class="error" *ngIf="errorMessage">
  <span>{{errorMessage | translate}}</span>
  <fa-icon (click)="errorMessage = null" size="lg" [icon]="['fas', 'times-circle']"></fa-icon>
</div>
<div class="loader" *ngIf="coreService.loadingState !== 'NONE'">
  <img src="assets/logo/splash.svg" alt="Destiny Recipes">
  <span>{{'LOADING_STATES.' + coreService.loadingState | translate}}
  </span>
  <fa-icon [icon]="['fas', 'spinner']" animation="spin"></fa-icon>
</div>
